@import '../../colors.scss';

.button {
	border: 0;
    padding: 10px 5px;
    border-radius: 5px;
    outline: none;
    // min-width: 120px;
    cursor: pointer;
    font-size: 1em;
    margin: 5px;
    color: $primaryText;    
    flex: 1;    	
    background-color: $normalText;
    position: relative;
}

.button.active {
	background-color: $thirdText!important;
	color: $primaryText!important;
	border-color: $thirdText!important;
}


.button.active.buttonArrow::after {
    content: " ";            
    border-width: 5px;
    border-style: solid;
    border-color: $thirdText transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -10px;
  }
