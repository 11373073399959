.ListFlavorsModal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 4;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;

  ::-webkit-scrollbar-track {
    background-color: #f9e4c9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fdbe71;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff8c00;
  }

  .maskCardRow {
    position: absolute !important;
    width: 90% !important;
    height: calc(100vh - 80px);
    max-height: 750px;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    margin: auto !important;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    z-index: 10 !important;
    max-width: 1280px;
    min-width: 580px;

    .off {
      color: #f6b45e;
      font-size: 30px;
      width: 35px;
      height: 35px;
      border: 1px solid #768198;
      border-radius: 35px;
      line-height: 35px;
      text-align: center;
      margin-right: 5px;
      cursor: pointer;
    }

    .searchDiv {
      margin: 10px 6px !important;
      padding: 0 !important;
    }

    .searchInput {
      flex: 1;
      max-width: 40%;
      height: 40px;
      box-sizing: border-box;
      padding-left: 40px;
      border: 1px solid #b3b3b3;
      border-radius: 6px;
      background-color: #f7f7f7;
      background-image: url('../../assets/Search.png');
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: 10px;
    }

    .ul {
      width: calc(100% - 10px);
      margin-top: 10px;
      margin-left: 6px;
      height: calc(100vh - 366px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      overflow: auto;
    }

    .flaovrlist {
      width: 320px;
      display: flex;
      border-top: 2px solid #ffcdcf;
      border-bottom: 2px solid #ffcdcf;
      margin: 0 20px 0 0;
      margin-bottom: -2px;
      overflow-x: auto;
      align-items: center;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;

      .img {
        width: 50px;
        height: 50px;
        margin: 5px 0 5px 5px;
      }

      input {
        width: 18px;
        height: 18px;
      }
    }
  }
}